import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        authRequired: true,
        pageTitle: "داشبورد",
        breadcrumb: [
          {
            text: "داشبورد",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: "/admins",
    //   name: "admins",
    //   component: () => import("@/views/admins/Index.vue"),
    //   meta: {
    //     authRequired: true,
    //     pageTitle: "مدیران",
    //     breadcrumb: [
    //       {
    //         text: "لیست مدیران",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/menus",
      name: "menus",
      component: () => import("@/views/menus/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "منو ها",
        breadcrumb: [
          {
            text: "لیست منو ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/menus/update/:id",
      name: "menu-update",
      component: () => import("@/views/menus/Update.vue"),
      meta: {
        authRequired: true,
        pageTitle: "آپدیت منو",
        breadcrumb: [
          {
            text: "لیست منو ها",
            to: "/menus",
          },
          {
            text: "آپدیت منو",
            active: true,
          },
        ],
      },
    },
    {
      path: "/games",
      name: "games",
      component: () => import("@/views/games/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "بازی ها",
        breadcrumb: [
          {
            text: "لیست بازی ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/games/create",
      name: "game-create",
      component: () => import("@/views/games/Create.vue"),
      meta: {
        authRequired: true,
        pageTitle: "ثبت بازی جدید",
        breadcrumb: [
          {
            text: "لیست بازی ها",
            to: "/games",
          },
          {
            text: "ثبت بازی جدید",
            active: true,
          },
        ],
      },
    },
    {
      path: "/games/update/:id",
      name: "game-update",
      component: () => import("@/views/games/Update.vue"),
      meta: {
        authRequired: true,
        pageTitle: "لیست بازی ها",
        breadcrumb: [
          {
            text: "لیست بازی ها",
            to: "/games",
          },
          {
            text: "ویرایش بازی",
            active: true,
          },
        ],
      },
    },
    {
      path: "/text-content",
      name: "text-content",
      component: () => import("@/views/text-content/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "محتوا متنی",
        breadcrumb: [
          {
            text: "محتوا متنی",
            active: true,
          },
        ],
      },
    },
    {
      path: "/text-content/create",
      name: "text-content-create",
      component: () => import("@/views/text-content/Create.vue"),
      meta: {
        authRequired: true,
        pageTitle: "ثبت صفحه جدید",
        breadcrumb: [
          {
            text: "لیست صفحات",
            to: "/text-content",
          },
          {
            text: "ثبت صفحه جدید",
            active: true,
          },
        ],
      },
    },
    {
      path: "/text-content/update/:id",
      name: "text-content-update",
      component: () => import("@/views/text-content/Update.vue"),
      meta: {
        authRequired: true,
        pageTitle: "آپدیت صفحه",
        breadcrumb: [
          {
            text: "لیست صفحات",
            to: "/text-content",
          },
          {
            text: "آپدیت صفحه",
            active: true,
          },
        ],
      },
    },
    {
      path: "/categories",
      name: "categories",
      component: () => import("@/views/categories/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "دسته بندی",
        breadcrumb: [
          {
            text: "لیست دسته بندی ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/categories/create",
      name: "categories-create",
      component: () => import("@/views/categories/Create.vue"),
      meta: {
        authRequired: true,
        pageTitle: "ثبت دسته بندی جدید",
        breadcrumb: [
          {
            text: "لیست دسته بندی ها",
            to: "/categories",
          },
          {
            text: "ثبت دسته بندی جدید",
            active: true,
          },
        ],
      },
    },
    {
      path: "/categories/:id/update",
      name: "categories-update",
      component: () => import("@/views/categories/Update.vue"),
      meta: {
        authRequired: true,
        pageTitle: "آپدیت دسته بندی",
        breadcrumb: [
          {
            text: "لیست دسته بندی ها",
            to: "/categories",
          },
          {
            text: "آپدیت دسته بندی",
            active: true,
          },
        ],
      },
    },
    {
      path: "/auth/login",
      name: "login",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        authRequired: false,
        layout: "full",
      },
    },
    {
      path: "/auth/otp",
      name: "otp",
      component: () => import("@/views/auth/OTP.vue"),
      meta: {
        authRequired: false,
        layout: "full",
      },
    },
    {
      path: "/file-manager",
      name: "file-manager",
      component: () => import("@/views/file-manager/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "مدیریت فایل ها ",
        breadcrumb: [
          {
            text: "مدیریت فایل ها ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/attributes",
      name: "attributes",
      component: () => import("@/views/attributes/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "ویژگی ها",
        breadcrumb: [
          {
            text: "لیست ویژگی ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/attributes/update/:id",
      name: "attributes-update",
      component: () => import("@/views/attributes/Update.vue"),
      meta: {
        authRequired: true,
        pageTitle: "ویژگی ها",
        breadcrumb: [
          {
            text: "لیست ویژگی ها",
            to: "/attributes",
          },
          {
            text: "ویرایش ویژگی",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tags",
      name: "tags",
      component: () => import("@/views/tags/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "ساخت برچسب",
        breadcrumb: [
          {
            text: "ساخت برچسب جدید‌  ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/comments",
      name: "comments",
      component: () => import("@/views/comments/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "مدیریت نظرات",
        breadcrumb: [
          {
            text: "مدیریت نظرات",
            active: true,
          },
        ],
      },
    },
    {
      path: "/messages",
      name: "messages",
      component: () => import("@/views/messages/Index.vue"),
      meta: {
        authRequired: true,
        pageTitle: "مدیریت پیام ها",
        breadcrumb: [
          {
            text: "مدیریت پیام ها",
            active: true,
          },
        ],
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        authRequired: false,
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  // console.log(axios.defaults.headers.common);
  // Remove initial loading
  if (to.meta.authRequired===true) {
    if (!!window.localStorage.getItem('access_token')) {
      next()
    }else{
        // uncomment below code to enable guard
      next('/auth/login')
      // uncomment below code to disabled guard
      // next()
    }
  }else{
    next()
  }
})

export default router